<template>
  <v-dialog
    v-model="editDialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Edit Company profile image and name.
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column"
        style="font-family:'Poppins-Medium';color: #1e1e1e"
      >
        Select Profile
        <div class="d-flex justify-center">
          <editable-avatar
            :image="profile.midThumbnail ? profile.midThumbnail : profile.image"
            :onImageSelected="onImageSelected"
          />
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
         Company Name
        </div>
        <v-row>
          <v-col>
            <v-text-field
              label="Company Name"
              solo
              flat
              outlined
              class="mt-2"
              v-model="profile.name"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
          <!-- <v-col>
            <v-text-field
              label="Last Name"
              solo
              flat
              outlined
              class="mt-2"
              v-model="profile.last_name"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-if="message">{{ message }}</v-alert>
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#0a5b8a"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  props: {
    editDialog: {
      type: Boolean,
      default: false
    },
    selected: Object,
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      code: "",
      name: "",
      message: null,
      loading: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
      rules: {
        required: value => !!value || "Required."
      },
      selectedImage: null,
      profile: {}
    };
  },
  methods: {
    ...mapActions("company", {
      updateCompany: "updateCompany"
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
  if (!this.profile.image && !this.selectedImage) {
    this.message = "Please select the profile image.";
    return;
  }
  if (!this.profile.name) {
    this.message = "Please add Company name.";
    return;
  }

  this.message = null;
  var formData = new FormData();
  if (this.selectedImage) {
    formData.append("image", this.selectedImage);
  }
  formData.append("_id", this.profile._id);
  formData.append("name", this.profile.name);

  this.loading = true;
  this.updateCompany(formData)
    .then((updatedCompany) => {
      this.loading = false;
      this.$emit("company-updated", updatedCompany);
      this.onClose(); 
    })
    .catch(error => {
      this.loading = false;
      if (error.response) {
        this.message = error.response.data.message;
      } else {
        this.message = error;
      }
    });
},
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    }
  },
  watch: {
    selected(newValue) {
      this.profile = { ...newValue };
    }
  },
  mounted() {
    this.profile = { ...this.selected };
  }
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
